import _isequal from 'lodash.isequal';
import React, { memo } from 'react';

import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { PuidsType } from 'config/constants/common';
import { PROJECT_ALIAS_BY_ID } from 'config/constants/projects/constants';
import { useAppSelector } from 'store/hooks';
import { domainGenerator } from 'utils/urlGenerator';

import { NewsWidgetBase } from '../NewsWidgetBase';

import { useProjectNews } from './hooks/useProjectNews';

type ProjectNewsWidgetPropsType = {
  projectId: ProjectType['id'];
  excludedIds?: CardData['id'][];
  newsCount?: number;
  level?: number;
  withAd?: boolean;
  puids?: PuidsType;
};

/**
 * Виджет новостей по топику
 * @param props.projectId - id вертикали, которая рисуется внутри
 * @param props.excludedIds - исключенные из выдачи кластера
 * @param props.newsCount - кол-во новостей в виджете
 * @param props.withAd - функция для получения компонента рекламы
 * @param props.level - номер этажа на котором отображается виджет, нужно для отправки цели
 * @param props.puids - пуиды для рекламы
 */
export const ProjectNewsWidgetRedesign = memo(
  function ProjectNewsWidgetRedesign({
    projectId,
    excludedIds = [],
    newsCount,
    withAd,
    level,
    puids,
  }: ProjectNewsWidgetPropsType) {
    const domainConfig = useAppSelector(selectDomainConfig);
    const { title, clusters, isError, fetchData } = useProjectNews({
      projectId,
      excludedIds,
      limit: newsCount,
    });

    const clustersByCount = newsCount ? clusters.slice(0, newsCount) : clusters;
    const projectAlias = PROJECT_ALIAS_BY_ID[projectId];
    const url = domainGenerator(domainConfig, projectId);

    return (
      <NewsWidgetBase
        title={title}
        url={url}
        alias={projectAlias}
        clusters={clustersByCount}
        isError={isError}
        level={level}
        fetchData={fetchData}
        withAd={withAd}
        puids={puids}
      />
    );
  },
  (prev, next) =>
    prev.projectId === next.projectId &&
    _isequal(prev.excludedIds, next.excludedIds) &&
    prev.newsCount === next.newsCount,
);
