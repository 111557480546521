import { useCallback, useMemo } from 'react';

import { useTopicsMenu } from 'common/components/TopicsMenu/useTopicsMenu';
import { selectPageName } from 'common/redux/appController';
import { selectTopicPageTopicAlias } from 'common/redux/pages/topic/selectors';
import { selectProjectId } from 'common/redux/runtime/selectors';
import { GREEN_TOPIC_ALIASES } from 'common/routes/topic/constants';
import { PAGE_TYPE } from 'config/constants/routerName';
import { useAppSelector } from 'store/hooks';
import { getTopicAliasesForWidgets } from 'utils/getTopicAliasesForWidgets';

/**
 * Хук, выдающий функцию, выдающую топик по переданному индексу.
 * @param excludedTopicAlias - алиас исключаемого топика.
 * @returns Функция, которая возвращает один из топиков в списке по индексу.
 *  Если индекс больше, чем длина массива с топиками, то отсчет начинается с нуля.
 */
export const useTopicAliasesForSideWidgets = () => {
  const projectId = useAppSelector(selectProjectId);
  const pageName = useAppSelector(selectPageName);
  const topicAlias = useAppSelector(selectTopicPageTopicAlias);

  const topics = useTopicsMenu(projectId);

  const excludedTopicAliases = useMemo(() => [...GREEN_TOPIC_ALIASES], []);

  if (pageName === PAGE_TYPE.topic && topicAlias) {
    excludedTopicAliases.push(topicAlias);
  }

  const topicsForWidget = useMemo(
    () =>
      getTopicAliasesForWidgets({ projectId, topics, excludedTopicAliases }),
    [projectId, topics, excludedTopicAliases],
  );

  return useCallback(
    (index: number) => topicsForWidget[index % topicsForWidget.length],
    [topicsForWidget],
  );
};
