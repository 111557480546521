import cn from 'classnames';
import React, { ReactNode } from 'react';
import Helmet from 'react-helmet';
import { shallowEqual, useSelector } from 'react-redux';

import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectDomainConfig,
  selectPostfix,
  selectProjectAlias,
  selectProjectName,
} from 'common/redux/runtime/selectors';
import { cropImage } from 'common/utils/crop';
import {
  HEAD_DOMAIN,
  ID_PROJECT_BY_ALIAS,
  PROJECT_OG_IMG,
} from 'config/constants/projects/constants';
import { domainGenerator } from 'utils/urlGenerator';

import { getCustomCanonical, getMetaContent } from '../utils';

const VERTICALS_WITHOUT_ARCHIVE = [
  'sport',
  'finance',
  'woman',
  'weekend',
  'doctor',
  'travel',
];

type BuildPageTitleType = (props: {
  titleWithoutPostfix: boolean;
  projectName: ProjectType['name'];
  basePageTitle: string;
}) => string;

/**
 * Создание заголовка страницы
 * @param titleWithoutPostfix - флаг убирающий постфикс с названием вертикали из тайтла
 * @param projectName - имя проекта
 * @param basePageTitle - основа заголовка страницы
 * @returns заголовок страницы
 */
const buildPageTitle: BuildPageTitleType = ({
  titleWithoutPostfix,
  projectName,
  basePageTitle,
}) => {
  if (!basePageTitle) {
    return projectName;
  }

  if (titleWithoutPostfix) {
    return basePageTitle;
  }

  return `${basePageTitle} - ${projectName}`;
};

type HeadPropsType = {
  top100PageType?: string;
  robotsExtra?: string;
  pageType?: string;
  pageTitle?: string;
  pageDescription?: string;
  pageKeywords?: string;
  pageImage?: string;
  pageImageWidth?: string;
  pageImageHeight?: string;
  pageUrl: UrlType;
  nextUrl?: string;
  prevUrl?: string;
  rssUrl?: string;
  rssTitle?: string;
  ramblerClid?: string;
  ogTitle?: string;
  ogUrl?: string;
  pubdate?: string;
  children?: ReactNode;
  isMobile?: boolean;
  shouldUsePredefinedCanonical?: boolean;
  maxImagePreview?: string;
  maxVideoPreview?: number;
  noindex?: boolean;
  isPodcast?: boolean;
  titleWithoutPostfix?: boolean;
  clusterImage?: string;
};

/**
 * Мета и сео разметка страницы
 * @param top100PageType - шапка топ-100 разметки на body
 * @param robotsExtra - дополнительный параметр индексирования сайта для роботов поисковых систем
 * @param pageType - тип страницы
 * @param pageTitle - заголовок страницы
 * @param pageDescription - описание страницы
 * @param pageKeywords - ключевые слова страницы
 * @param pageImage - изображение страницы
 * @param pageImageWidth - ширина изображения
 * @param pageImageHeight - высота изображения
 * @param pageUrl - url
 * @param nextUrl - следующая страница
 * @param prevUrl - предыдущая страница
 * @param rssUrl - url rss
 * @param rssTitle - заголовок rss
 * @param ramblerClid - параметр сбора данных для обменных сетей с расшариванием трафика
 * @param ogTitle - заголовок для Open Graph
 * @param ogUrl - url для  Open Graph
 * @param pubdate - дата публикации
 * @param children - children
 * @param isMobile - флаг mobile или desktop
 * @param shouldUsePredefinedCanonical - флаг отображения/скрытия canonical
 * @param maxImagePreview - максимальный размер изображений для выдачи в результатах поиска
 * @param maxVideoPreview - максимальная длина превью видео для выдачи в результатах поиска
 * @param noindex - флаг отображения noindex,nofollow
 * @param isPodcast - флаг что кластер из рубрики подкастов
 * @param titleWithoutPostfix - флаг убирающий постфикс с названием вертикали из тайтла
 */
export const Head = ({
  top100PageType = 'none',
  robotsExtra,
  pageType = 'website',
  pageTitle = '',
  pageDescription,
  pageKeywords,
  pageImage,
  pageImageWidth = '1200',
  pageImageHeight = '630',
  pageUrl,
  nextUrl,
  prevUrl,
  rssUrl,
  rssTitle,
  ramblerClid,
  ogTitle,
  ogUrl,
  pubdate,
  children,
  clusterImage,
  isMobile = true,
  shouldUsePredefinedCanonical = true,
  noindex = false,
  maxImagePreview,
  maxVideoPreview,
  isPodcast = false,
  titleWithoutPostfix = false,
}: HeadPropsType) => {
  const alias = useSelector(selectProjectAlias);
  const projectName = useSelector(selectProjectName);
  const domainConfig = useSelector(selectDomainConfig, shallowEqual);
  const postfix = useSelector(selectPostfix);

  const top100Attribute = useTop100AttributeWithValue(top100PageType);
  const customCanonical = getCustomCanonical(alias, pageUrl);
  const canonicalPageUrl = customCanonical?.pageUrl || pageUrl;
  const projectAlias = customCanonical?.projectAlias || alias;

  // нужен, чтобы на клиенте у body не изменять класс
  const isSSR = typeof window === 'undefined';
  const isArchiveDisabled = VERTICALS_WITHOUT_ARCHIVE.includes(projectAlias);
  const robots = isArchiveDisabled ? 'noyaca,noodp,noarchive' : 'noyaca,noodp';
  const domain = domainGenerator(
    domainConfig,
    ID_PROJECT_BY_ALIAS[projectAlias],
  );
  const isProxyAutorambler =
    postfix.includes('autorambler') || postfix.includes('avtorambler');
  const googleMetaContent = getMetaContent(domainConfig.postfix);

  const image =
    pageImage ||
    PROJECT_OG_IMG[projectAlias] ||
    cropImage(
      `${domain}/special/${projectAlias}.jpg`,
      `e${pageImageWidth}x${pageImageHeight}i`,
    );

  const title = buildPageTitle({
    basePageTitle: pageTitle,
    projectName,
    titleWithoutPostfix,
  });

  /**
   * Подумать над тем, чтобы вынести это в отдельную подтулзу
   */
  const description = isProxyAutorambler
    ? pageDescription?.replace(/Рамблер\/(А|а)вто/, projectName)
    : pageDescription;

  return (
    <Helmet>
      {isSSR && (
        <body
          className={cn('no-js', `p-${projectAlias}`)}
          {...top100Attribute}
        />
      )}

      {noindex ? <meta name="robots" content="noindex,nofollow" /> : null}

      {/** Подтверждения для гугла */}
      {googleMetaContent.map((content) => (
        <meta key={content} name="google-site-verification" content={content} />
      ))}

      <meta
        name="robots"
        content={robotsExtra ? `${robots},${robotsExtra}` : robots}
      />

      {maxImagePreview && (
        <meta name="robots" content={`max-image-preview:${maxImagePreview}`} />
      )}

      {maxVideoPreview && (
        <meta name="robots" content={`max-video-preview:${maxVideoPreview}`} />
      )}

      {/* page block */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {pageKeywords && pageKeywords.length && (
        <meta name="keywords" content={pageKeywords} />
      )}
      {pageUrl && shouldUsePredefinedCanonical ? (
        <link rel="canonical" href={`${domain}${canonicalPageUrl}`} />
      ) : null}
      {nextUrl && <link rel="next" href={`${domain}${nextUrl}`} />}
      {prevUrl && <link rel="prev" href={`${domain}${prevUrl}`} />}

      {rssUrl && (
        <link
          type="application/rss+xml"
          rel="alternate"
          title={rssTitle ? `${rssTitle} — ${projectName}` : projectName}
          href={`${domain}${rssUrl}`}
        />
      )}
      {isPodcast && (
        <link
          type="application/rss+xml"
          rel="alternate"
          title="Рамблер, какие новости?"
          href="https://feeds.buzzsprout.com/1116173.rss"
        />
      )}
      {/* end page */}

      {ramblerClid && (
        <meta
          property="rambler:clid"
          content={`${HEAD_DOMAIN}?clid=${ramblerClid}`}
        />
      )}
      {isPodcast && isMobile && (
        <meta name="apple-itunes-app" content="app-id=1515478932" />
      )}

      {/* og block */}
      <meta property="og:site_name" content={projectName} />
      {ogTitle || pageTitle ? (
        <meta property="og:title" content={ogTitle || pageTitle} />
      ) : null}
      {ogUrl || pageUrl ? (
        <meta property="og:url" content={`${domain}${ogUrl || pageUrl}`} />
      ) : null}
      <meta property="og:type" content={pageType} />
      <meta property="og:image" content={image} />
      <meta property="vk:image" content={image} />
      <meta property="rambler:image" content={clusterImage || image} />
      {pageDescription && (
        <meta property="og:description" content={description} />
      )}
      <meta property="og:locale" content="ru_RU" />
      {pubdate && <meta property="og:pubdate" content={pubdate} />}

      {/* https://jira.rambler-co.ru/browse/NEWS-10355 теги твиттера не нужны */}
      {/* <meta name="twitter:image" content={image} /> */}
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      {/* end og */}

      {children}
    </Helmet>
  );
};
