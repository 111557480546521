import { createContext, useContext } from 'react';

type State = number | null;
const initialState: State = null;

const LevelContext = createContext<State>(initialState);
export const LevelContextProvider = LevelContext.Provider;

/**
 * Контекст этажа. Позволяет узнать к какому этажу относится та или иная карточка.
 */
export const useLevel = () => {
  const level = useContext(LevelContext);

  return level ?? initialState;
};
