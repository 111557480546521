import cn from 'classnames';
import React, { MutableRefObject, memo } from 'react';
import { useSelector } from 'react-redux';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

export type ItemPropsType = {
  url: CardData['url'];
  title: CardData['title'];
  publicationTime: CardData['publicationTime'];
  count: number | undefined;
  index: number;
  linkRef?: MutableRefObject<HTMLAnchorElement>;
  onClick?: () => void;
};

/**
 * Компонент новости для виджета топа новостей.
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.publicationTime - время публикации;
 * @param props.count - количество комментариев в кластере;
 * @param props.index - индекс айтема для топ100.
 * @param props.linkRef - реф для ссылки
 * @param props.onClick - событие по клику для ссылки
 */
export const Item = memo(
  ({
    url,
    title,
    publicationTime,
    count,
    index,
    linkRef,
    onClick,
  }: ItemPropsType) => {
    const isMobile = useSelector(selectIsMobile);

    const top100Attribute = useTop100AttributeWithValue(`item::${index + 1}`);

    return (
      <li className={cn(s.item, { [s.mobile]: isMobile })}>
        <a href={url} ref={linkRef} onClick={onClick} {...top100Attribute}>
          <p>
            {title}
            <span className={s.meta}>
              <span className={s.time}>
                {!!publicationTime &&
                  datesince({
                    pubdate: new Date(publicationTime).getTime() / 1000,
                    isShort: true,
                  })}
              </span>
              <CommentsCounter count={count} className={s.comments} />
            </span>
          </p>
        </a>
      </li>
    );
  },
);
