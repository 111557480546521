import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { CardWithRcm } from 'common/components/CardWithRcm';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import {
  selectCurrentServerTime,
  selectProjectAlias,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType, defaultCallback } from 'config/constants/common';
import { useTopicAliasesForSideWidgets } from 'desktop/hooks/useTopicAliasesForSideWidgets';
import { useAppSelector } from 'store/hooks';

import { useSecondLevelWidgetOneColumn } from '../../hooks';

import s from './styles.module.css';

type InjectorType = (index: number, puids: PuidsType) => React.ReactNode;

type SecondLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
  bottomAdName: Banners;
  level: number;
  floorIndex: number;
  indexOffset?: number;
  injectElements?: InjectorType;
  widgetsCompact?: React.ReactNode;
};

/**
 * Компонент для отрисовки новостей начиная со второго экрана для вертикали Новости.
 * @param props.clusterIds - список идентификаторов кластеров;
 * @param props.puids - объект настроек для рекламы;
 * @param props.bottomAdName - название баннера в конце блока;
 * @param props.level - этаж для передачи в ЯМ;
 * @param props.floorIndex - индекс итерируемого этажа
 * @param props.indexOffset - офсет индексов для коректного подсчета в топ100;
 * @param props.injectElements - функция для внедрения компонентов внутрь списка новостей;
 * @param props.widgetsCompact - компонент с компонентами виджетов для боковой колонки на узком экране;
 */
export const SecondLevel = memo(function RedesignNewsSecondLevel({
  clusterIds,
  puids,
  bottomAdName,
  level,
  floorIndex,
  indexOffset = 0,
  injectElements = defaultCallback as InjectorType,
  widgetsCompact,
}: SecondLevelPropsType) {
  const projectId = useAppSelector(selectProjectId);
  const projectAlias = useAppSelector(selectProjectAlias);
  const serverTime = useAppSelector(selectCurrentServerTime);

  const getTopicAliasForSideWidget = useTopicAliasesForSideWidgets();

  const widgets = useSecondLevelWidgetOneColumn({
    projectId,
    projectAlias,
    clusterId: clusterIds[0],
    // В NEWS-12018 добавлен + 1. По спилу удалить инкремент на обоих строчках
    topicAlias: getTopicAliasForSideWidget(floorIndex + 1),
    floorIndex: floorIndex + 1,
  });

  return (
    <ReachGoalLevelWrapper level={level} styles={s.root}>
      <div className={s.content}>
        <div className={s.newsBlock}>
          {clusterIds.map((clusterId, index) => {
            const element = injectElements?.(index, puids);
            const news = (
              <CardWithRcm
                key={clusterId}
                currentTime={serverTime}
                type="base-desktop"
                clusterId={clusterId}
                index={index + indexOffset}
                isLazy
              />
            );

            if (element) {
              return [news, element];
            }

            return news;
          })}
        </div>
      </div>
      <div className={s.widgets}>{widgets}</div>
      <div className={cn(s.sideAd)}>
        <div className={cn(s.column, s.sticky)}>
          <Ad
            puids={puids}
            name={Banners['240x400']}
            withoutMarginBottom
            isLazy={false}
          />
          <div className={s.widgetsCompact}>{widgetsCompact}</div>
        </div>
      </div>
      <div className={s.bottomAd}>
        <Ad name={bottomAdName} puids={puids} withoutMarginBottom />
      </div>
    </ReachGoalLevelWrapper>
  );
});
