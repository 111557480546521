import { customCanonicals } from './config/customCanonicals';

/**
 * Получение кастомного каноникала
 * @param projectAlias - alias текущего проекта
 * @param pageUrl - урл страницы без домена
 */
export const getCustomCanonical = (
  projectAlias: ProjectType['alias'],
  pageUrl: UrlType,
) => {
  const customCanonical = customCanonicals.find(
    (canonicalObject) =>
      canonicalObject.input.projectAlias === projectAlias &&
      // slice тк pageUrl начинается и заканчивается '/'
      canonicalObject.input.path === pageUrl.slice(1, -1),
  );

  return customCanonical
    ? {
        projectAlias: customCanonical.output?.projectAlias,
        pageUrl: `/${customCanonical.output?.path}/`,
      }
    : undefined;
};

/**
 * Получение content для мета тега Google Search Console
 * @param postfix - runtime.domainConfig.postfix проекта
 */
export const getMetaContent = (postfix: string) => {
  if (postfix.includes('newsua') || postfix.includes('news.rambler.ua'))
    return [
      've7zMi3bc_a19EOftZQDIvnQ3oabTJwxIsUxFN_60Kw',
      'ANKBANCycyPCuZWZLctoY9PQi2cbC2sGfhrr0Dsj4YA',
    ];

  if (postfix.includes('finance-rambler'))
    return [
      'Y6LLfqc1GS6gxeINYphmBiB1NVNaZ6IjBLjkO9uUZZI',
      'EjuMYLr9oixobkMFlnwhgHbtv37MNNZUYlCgqyoahns',
    ];

  if (postfix.includes('avtorambler'))
    return ['_r68GPCzghX-gaF5swJ2JzumEDQJL0Xc7owJFOCLgcA'];

  return ['gCUlc75xaB8fn5MS524-Np496euN9aiV0FI4pS12R6A'];
};
