import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { Card } from 'common/components/Card';
import { ReachGoalGigaChatWrapper } from 'common/components/metrics/ReachGoalGigaChatWrapper';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { SberWidgetWrapper } from 'common/components/SberWidgetWrapper';
import {
  selectCurrentServerTime,
  selectIsRussia,
  selectPromoWidgetEnabled,
  selectPromoWidgetPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import { selectIsGigaChatShow } from 'common/redux/selectors';
import Banners from 'config/constants/banner/banners';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/constants/banner/contants';
import { PuidsType } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

import { MainCard } from '../MainCard';

import s from './styles.module.css';

type FirstLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
};

/**
 * Компонент первого этажа новостной вертикали.
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - файл с настройками рекламы.
 */
export const FirstLevel = memo(function FirstLevel({
  clusterIds,
  puids,
}: FirstLevelPropsType) {
  const isRussiaRegion = useAppSelector(selectIsRussia);
  const isPromoWidgetEnabled = useAppSelector(selectPromoWidgetEnabled);
  const isPromoWidgetPlaceholderEnabled = useAppSelector(
    selectPromoWidgetPlaceholderEnabled,
  );
  const gigaEnabled = useAppSelector(selectIsGigaChatShow);
  const currentTime = useAppSelector(selectCurrentServerTime);

  return (
    <div className={s.root}>
      <div className={s.main}>
        {gigaEnabled && (
          <ReachGoalGigaChatWrapper className={cn(s.block, s.giga)} />
        )}
        <ReachGoalLevelWrapper level={1} styles={s.news}>
          {clusterIds.map((clusterId, index) => {
            if (index === 0) {
              return (
                <MainCard
                  key={clusterId}
                  clusterId={clusterId}
                  currentTime={currentTime}
                />
              );
            }

            return (
              <Card
                key={clusterId}
                currentTime={currentTime}
                type="top-secondary-desktop"
                clusterId={clusterId}
                index={index + 1}
              />
            );
          })}
        </ReachGoalLevelWrapper>
      </div>
      <div className={s.sideAd}>
        <div className={s.sticky}>
          {isRussiaRegion && isPromoWidgetEnabled && (
            <Ad
              name={Banners.PromoWidget}
              puids={puids}
              isLazy={false}
              // Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился
              className={cn(
                s.promo,
                isPromoWidgetPlaceholderEnabled && s.visible,
              )}
              preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
              disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
              loadedClassName={s.visible}
            />
          )}
          <SberWidgetWrapper />
          <Ad
            puids={puids}
            name={Banners['240x400']}
            withoutMarginBottom
            isLazy={false}
          />
          <Ad
            name={Banners.Context}
            puids={puids}
            className={cn(
              s.adPlaceholder,
              'banner--native-context_widget_relaunch',
            )}
            loadedClassName={s.adPlaceholderLoaded}
            isLazy={false}
            preloadHeight={100}
          />
        </div>
      </div>
    </div>
  );
});
