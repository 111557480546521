import { memo, useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectAllClusterIds } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { fetchFullRecsData } from 'common/redux/pages/home/asyncs';
import { selectHomePageClusterIds } from 'common/redux/pages/home/selectors';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { useFetchRecsData } from 'desktop/hooks/useFetchRecsData';
import { useAppSelector } from 'store/hooks';

/**
 * Компонент с логикой подрузки рекомендов для домашней страницы.
 */
export const HomeRecommends = memo(() => {
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const topClusterIds = useAppSelector(selectHomePageClusterIds, shallowEqual);
  // Рекомендательные кластера из колонки
  const topProjectWidgetsClusterIds = useAppSelector(
    selectWidgetsTopProjectAllClusterIds,
    shallowEqual,
  );
  const topMainWidgetsClustersIds = useAppSelector(
    selectWidgetsTopMainClusterIDs,
    shallowEqual,
  );

  const excludedClustersArray = useMemo(() => {
    if (forceRedesign) {
      return [topClusterIds, topProjectWidgetsClusterIds];
    }

    return [
      topClusterIds,
      topProjectWidgetsClusterIds,
      topMainWidgetsClustersIds,
    ];
  }, [
    forceRedesign,
    topClusterIds,
    topProjectWidgetsClusterIds,
    topMainWidgetsClustersIds,
  ]);

  useFetchRecsData({
    fetchRecsData: fetchFullRecsData,
    excludedClustersArray,
  });

  return null;
});
