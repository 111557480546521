import React from 'react';

import { useWidgetReachGoal } from 'common/components/metrics/utils';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';

import { TopNewsWidget } from '../components/TopNewsWidget';
import { useTopMainNewsWidget } from '../hooks/useTopMainNewsWidget';

export const TOP_NEWS_WIDGET_HEIGHT_DEFAULT = 540;

type TopMainNewsWidgetPropsType = {
  excludeIds?: CardData['id'][];
  level?: number;
};

/**
 * Виджет топа новостей главной.
 * @param props.excludeIds - исключаемые из отображения id.
 */
export const TopMainNewsWidget = withErrorBoundary(function TopMainNewsWidget({
  excludeIds = [],
  level,
}: TopMainNewsWidgetPropsType) {
  const { clusters, isError, fetchData } = useTopMainNewsWidget(excludeIds);

  const { reachGoalNewsClickCallback, widgetRef } = useWidgetReachGoal(level);

  return (
    <TopNewsWidget
      clusters={clusters}
      isError={isError}
      onRefresh={fetchData}
      title="Главное сейчас"
      url="https://www.rambler.ru/"
      top100Value="main_now"
      onClick={reachGoalNewsClickCallback}
      ref={widgetRef}
    />
  );
});
