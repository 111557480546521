import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from 'common/redux/appController/selectors';
import {
  selectProjectId,
  selectSberWidgetErid,
} from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { PAGE_TYPE } from 'config/constants/routerName';

import { SberWidget } from './components/SberWidget';

const DEFAULT_DISABLED_ERID_VALUE = '0';

/** Обертка над виджетом, отображающая его лишь на определенных страницах */
export const SberWidgetWrapper = memo(() => {
  const projectId = useSelector(selectProjectId);
  const pageName = useSelector(selectPageName);
  const erid = useSelector(selectSberWidgetErid);

  if (
    projectId !== PROJECT_IDS.Finance ||
    pageName !== PAGE_TYPE.home ||
    erid === DEFAULT_DISABLED_ERID_VALUE
  ) {
    return null;
  }

  return <SberWidget erid={erid} />;
});
