import React, { memo } from 'react';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { RamblerImageBg } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

const IMAGE_HEIGHT = 80;

type CardItemPropsType = {
  index: number;
  publicationTime: CardData['publicationTime'];
  url: CardData['url'];
  imageUrl: CardData['image']['url'];
  imageS3: CardData['image']['s3'];
  title: CardData['title'];
  commentsCount: CardData['commentsCount'];
  onClick?: () => void;
};

/**
 * Карточка новости для виджета новостей по топику
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.imageUrl - ссылка на источник картинки кластера;
 * @param props.imageS3 - флаг, что оригинал загружен в rstorage;
 * @param props.commentsCount - количество комментов;
 * @param props.publicationTime - время публикации;
 * @param props.index - индекс карточки для топ100;
 * @param props.onClick - индекс карточки для топ100.
 */
export const CardItem = memo(
  ({
    index,
    publicationTime,
    url,
    imageUrl,
    imageS3,
    title,
    commentsCount,
    onClick,
  }: CardItemPropsType) => {
    const top100AttributeWithValue = useTop100AttributeWithValue(
      `item::${index + 1}`,
    );

    return (
      <li>
        <a
          className={s.item}
          href={url}
          onClick={onClick}
          {...top100AttributeWithValue}
        >
          <RamblerImageBg
            height={IMAGE_HEIGHT}
            src={imageUrl}
            className={s.img}
            isS3={imageS3}
            isLazy
          />
          <div>
            <p className={s.title}>{title}</p>
            <div className={s.info}>
              <p className={s.date}>
                {!!publicationTime &&
                  datesince({
                    pubdate: new Date(publicationTime).getTime() / 1000,
                  })}
              </p>
              <CommentsCounter count={commentsCount} className={s.comments} />
            </div>
          </div>
        </a>
      </li>
    );
  },
);
