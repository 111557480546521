import React, { useId } from 'react';

import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { useCounterId } from 'utils/counters/yaMetrika';
import { COUNTER_ID } from 'utils/counters/yaMetrika/constants';

import { useLoadRamblerEmbed } from '../hooks/useLoadRamblerEmbed';
import s from '../styles.module.css';

const PARENT_ID = 'rambler-weather-container';
const WIDGET_ID = 'rambler-weather';

/**
 * Компонент с ембедом Рамблера - Выгодный курс обмена.
 */
export const RamblerCurrencyExchange = withErrorBoundary(
  function RamblerWeather() {
    const id = useId();
    const yandexCounterId = useCounterId(COUNTER_ID.WebMobile);

    const { embedRef, isInited } = useLoadRamblerEmbed({
      parentId: `${PARENT_ID}${id}`,
      widgetId: `${WIDGET_ID}${id}`,
      url: 'https://embeds.rambler.ru/finance/currency-exchange.js',
    });

    return (
      <div id={`${PARENT_ID}${id}`} className={isInited ? '' : s.placeholder}>
        <rambler-currency-exchange
          ref={embedRef}
          yandex-metrica-id={yandexCounterId}
        />
      </div>
    );
  },
);
