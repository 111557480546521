import loadable from '@loadable/component';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectProjectId } from 'common/redux/runtime/selectors';
import { PROJECT_IDS } from 'config/constants/projects/constants';

import { WidgetsColumnPropsType } from './typings';

/**
 * Защищаем от загрузки те компоненты, которые не нужны и не понадобятся.
 */
const FinanceWidgets = loadable(() => import('./components/FinanceWidgets'), {
  resolveComponent: (components) => components.FinanceWidgets,
});
const NewsWidgets = loadable(() => import('./components/NewsWidgets'), {
  resolveComponent: (components) => components.NewsWidgets,
});
const SportWidgets = loadable(() => import('./components/SportWidgets'), {
  resolveComponent: (components) => components.SportWidgets,
});
const AutoWidgets = loadable(() => import('./components/AutoWidgets'), {
  resolveComponent: (components) => components.AutoWidgets,
});
const WomanWidgets = loadable(() => import('./components/WomanWidgets'), {
  resolveComponent: (components) => components.WomanWidgets,
});
const DoctorWidgets = loadable(() => import('./components/DoctorWidgets'), {
  resolveComponent: (components) => components.DoctorWidgets,
});
const WeekendWidgets = loadable(() => import('./components/WeekendWidgets'), {
  resolveComponent: (components) => components.WeekendWidgets,
});
const KinoWidgets = loadable(() => import('./components/KinoWidgets'), {
  resolveComponent: (components) => components.KinoWidgets,
});
const TravelWidgets = loadable(() => import('./components/TravelWidgets'), {
  resolveComponent: (components) => components.TravelWidgets,
});

/**
 * Список виджетов для второй колонки
 * @param puids – объект пуидов для рекламы
 */
export const WidgetsColumn = memo(({ puids }: WidgetsColumnPropsType) => {
  const projectId = useSelector(selectProjectId);

  switch (projectId) {
    case PROJECT_IDS.Finance:
      return <FinanceWidgets puids={puids} />;

    case PROJECT_IDS.Sport:
      return <SportWidgets puids={puids} />;

    case PROJECT_IDS.Auto:
      return <AutoWidgets puids={puids} />;

    case PROJECT_IDS.Woman:
      return <WomanWidgets puids={puids} />;

    case PROJECT_IDS.Doctor:
      return <DoctorWidgets puids={puids} />;

    case PROJECT_IDS.Weekend:
      return <WeekendWidgets puids={puids} />;

    case PROJECT_IDS.Kino:
      return <KinoWidgets puids={puids} />;

    case PROJECT_IDS.Travel:
      return <TravelWidgets puids={puids} />;

    case PROJECT_IDS.News:

    // eslint-disable-next-line no-fallthrough
    default:
      return <NewsWidgets puids={puids} />;
  }
});
