import React, { memo } from 'react';

import { RefreshIcon } from 'icons';

import s from './styles.module.css';

type ErrorMessagePropsType = {
  onRefresh?: () => void;
};

/**
 * Компонент сообщения об ошибке при загрузке новостей в виджете
 * @param onRefresh – функция для загрузки данных в виджете
 */
export const ErrorMessage = memo(({ onRefresh }: ErrorMessagePropsType) => (
  <div className={s.root}>
    <p>Ошибка загрузки новостей. {onRefresh && 'Попробуйте еще раз'}</p>

    {onRefresh && (
      <button
        type="button"
        aria-label="Обновить новости"
        onClick={onRefresh}
        className={s.button}
      >
        <RefreshIcon className={s.icon} />
      </button>
    )}
  </div>
));
