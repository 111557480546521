import { createContext, useContext } from 'react';

export const PageContextForTrigger = createContext<{
  triggerReady: boolean;
  setTriggerReady: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  triggerReady: false,
  setTriggerReady: () => {},
});

export const usePageContextForTrigger = () => {
  const { triggerReady, setTriggerReady } = useContext(PageContextForTrigger);

  return { triggerReady, setTriggerReady };
};
