import md5 from 'md5';

const SECRET = 'jovfors6';
const PROXY_IMAGE_DOMAIN = 'img02.rl0.ru';
const VERTICAL_PROPORTION = 0.84;

/**
 * Генерация урла на img.rl0.ru (сервис кропа изображения рамблера)
 * для изменения размеров изображения
 * @param url - урл картинки на домене проекта (вертикали). Например news.rambler.ru
 * @param cropValue - значение кропа. Например c765x430
 */
export const cropImage = (
  url: string | undefined,
  cropValue: string,
): string => {
  if (url && cropValue && url.match(/rambler.ru/)) {
    const urlWithoutScheme = url.replace('https://', '').replace('http://', '');
    const str = `${cropValue}/${urlWithoutScheme}${SECRET}`;
    const md5string = md5(str);

    return `https://${PROXY_IMAGE_DOMAIN}/${md5string}/${cropValue}/${urlWithoutScheme}`;
  }

  return url || '';
};

export enum CROP_TYPES {
  eType,
  eTypeO,
  cType,
  cTypeO,
  Type,
  TypeO,
}

export interface ImageCropType {
  width: number;
  height: number;
  type?: CROP_TYPES;
}

type useOrientationCrop = (options: {
  width?: ImageCropType['width'];
  height?: ImageCropType['height'];
  image?: {
    width?: number | null;
    height?: number | null;
  };
  verticalType?: ImageCropType['type'];
  horizontalType?: ImageCropType['type'];
}) => ImageCropType | null;

/**
 * Кастомный хук для создания конфигурации кропа с учетом ориентации картинки
 * @params width - ширина обрезки картинки
 * @params height - высота обрезки картинки
 * @params image - объект с размерами картинки
 * @params verticalType - тип кропа, который будет для вертикального изображения
 * @params horizontalType - тип кропа, который будет для горизонтального изображения
 */
export const makeOrientationCrop: useOrientationCrop = ({
  width,
  height,
  image = {
    width,
    height,
  },
  verticalType = CROP_TYPES.eTypeO,
  horizontalType = CROP_TYPES.eType,
}): ImageCropType | null => {
  /**
   * Если размеров кропа не пришло, то и кроп нечего считать
   */
  const nullableSizes = [width, height, image.width, image.height];

  if (nullableSizes.some((item) => typeof item !== 'number')) {
    return null;
  }

  return {
    width: width!,
    height: height!,
    type:
      image.width! / (image.height as number) < VERTICAL_PROPORTION
        ? verticalType
        : horizontalType,
  };
};
