import _isequal from 'lodash.isequal';
import React, { memo } from 'react';

import { useWidgetReachGoal } from 'common/components/metrics/utils';
import { PuidsType } from 'config/constants/common';
import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';

import { CardItem } from '../CardItem';

type TopicNewsWidgetPropsType = {
  title: ATTopic['name'];
  clusters: CardData[];
  isError: boolean;
  fetchData: () => void;
  url: string;
  alias: string;
  level?: number;
  withAd?: boolean;
  puids?: PuidsType;
};

/**
 * Виджет новостей по топику
 * @param props.title - заголовок виджета
 * @param props.clusters - кластера виджета
 * @param props.isError - флаг ошибки
 * @param props.fetchData - функция для получения данных
 * @param props.url - url виджета при клике на "Больше новостей"
 * @param props.alias - alias для top100
 * @param props.level - уровень, на котором располагается виджет для ЯМетрики
 */
export const NewsWidgetBase = memo(
  function NewsWidgetBase({
    title,
    clusters,
    isError,
    fetchData,
    url,
    alias,
    level,
  }: TopicNewsWidgetPropsType) {
    const {
      reachGoalNewsClickCallback,
      reachGoalMoreNewsClickCallback,
      widgetRef,
    } = useWidgetReachGoal(level);

    return (
      <div ref={widgetRef}>
        <FeedWidgetLayout
          title={title}
          url={url}
          top100Value={alias}
          isError={isError}
          onRefresh={fetchData}
          onMoreNewsClick={reachGoalMoreNewsClickCallback}
        >
          {clusters.map(
            (
              {
                id,
                publicationTime,
                url,
                image,
                title: clusterTitle,
                commentsCount,
              },
              index,
            ) => (
              <CardItem
                key={id}
                index={index}
                publicationTime={publicationTime}
                url={url}
                imageUrl={image.url}
                imageS3={image.s3}
                title={clusterTitle}
                commentsCount={commentsCount}
                onClick={reachGoalNewsClickCallback}
              />
            ),
          )}
        </FeedWidgetLayout>
      </div>
    );
  },
  (prev, next) =>
    prev.alias === next.alias &&
    _isequal(prev.clusters, next.clusters) &&
    prev.fetchData === next.fetchData,
);
