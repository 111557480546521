import { SAVING_TOPIC_LIMIT } from 'desktop/components/FeedWidgetLayout/constants';

import { selectCardById } from '../../entries/selectors';
import { selectWidgets } from '../selectors';

import { topicNewsWidgetAdapter } from './adapter';

const selectors = topicNewsWidgetAdapter.getSelectors(
  (state: IAppState) => selectWidgets(state).topicNewsWidget,
);

export const selectTopicNewsWidget =
  (topicId: ATTopic['id'] | undefined) => (state: IAppState) =>
    typeof topicId === 'number'
      ? selectors.selectById(state, topicId)
      : undefined;

export const selectTopicNewsWidgetClustersIds =
  (topicId: ATTopic['id'] | undefined) => (state: IAppState) =>
    selectTopicNewsWidget(topicId)(state)?.clusterIds || [];

export const selectTopicNewsWidgetFilteredClustersIds =
  (
    excludedIds: CardData['id'][] = [],
    topicId: ATTopic['id'] | undefined,
    limit?: number,
  ) =>
  (state: IAppState) => {
    const clustersIds = selectTopicNewsWidgetClustersIds(topicId)(state);

    return clustersIds
      .filter((id) => !excludedIds.includes(`${id}`))
      .slice(0, limit || SAVING_TOPIC_LIMIT);
  };

export const selectTopicNewsWidgetCluster =
  (clusterIds: CardData['id'][]) => (state: IAppState) =>
    clusterIds.map((clusterId) => selectCardById(clusterId)(state)) || [];

export const selectTopicNewsWidgetClusterInCardData =
  (
    excludedIds: CardData['id'][] | undefined,
    topicId: ATTopic['id'] | undefined,
    limit?: number,
  ) =>
  (state: IAppState) =>
    selectTopicNewsWidgetCluster(
      selectTopicNewsWidgetFilteredClustersIds(
        excludedIds,
        topicId,
        limit,
      )(state),
    )(state);

export const selectTopicNewsWidgetLoadingState =
  (topicId: ATTopic['id'] | undefined) => (state: IAppState) =>
    selectTopicNewsWidget(topicId)(state)?.fetching;

export const selectTopicNewsWidgetErrorState =
  (topicId: ATTopic['id'] | undefined) => (state: IAppState) =>
    selectTopicNewsWidget(topicId)(state)?.error;
