import cn from 'classnames';
import _isequal from 'lodash.isequal';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { useWidgetReachGoal } from 'common/components/metrics/utils';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { FeedWidgetLayoutRedesign } from 'desktop/components/FeedWidgetLayoutRedesign';

import { CardItem } from '../CardItem';

import s from './styles.module.css';

type TopicNewsWidgetPropsType = {
  title: ATTopic['name'];
  clusters: CardData[];
  isError: boolean;
  fetchData: () => void;
  url: string;
  alias: string;
  level?: number;
  withAd?: boolean;
  puids?: PuidsType;
};

/**
 * Виджет новостей по топику
 * @param props.title - заголовок виджета
 * @param props.clusters - кластера виджета
 * @param props.isError - флаг ошибки
 * @param props.fetchData - функция для получения данных
 * @param props.url - url виджета при клике на "Больше новостей"
 * @param props.alias - alias для top100
 * @param props.withAd - флаг показа рекламы
 * @param props.level - уровень, на котором располагается виджет для ЯМетрики
 * @param props.puids - пуиды для рекламы
 */
export const NewsWidgetBase = memo(
  function NewsWidgetBase({
    title,
    clusters,
    isError,
    fetchData,
    url,
    alias,
    withAd,
    level,
    puids,
  }: TopicNewsWidgetPropsType) {
    const {
      reachGoalNewsClickCallback,
      reachGoalMoreNewsClickCallback,
      reachGoalAdClickCallback,
      widgetRef,
    } = useWidgetReachGoal(level);

    return (
      <div ref={widgetRef}>
        <FeedWidgetLayoutRedesign
          title={title}
          url={url}
          top100Value={alias}
          isError={isError}
          onRefresh={fetchData}
          onMoreNewsClick={reachGoalMoreNewsClickCallback}
        >
          {clusters.map(
            ({ id, url, title: clusterTitle, commentsCount }, index) => (
              <CardItem
                key={id}
                index={index}
                url={url}
                title={clusterTitle}
                commentsCount={commentsCount}
                onClick={reachGoalNewsClickCallback}
              />
            ),
          )}
          {withAd && (
            <li className={s.adWrapper}>
              <Ad
                name={Banners.Context}
                puids={puids}
                className={cn(
                  s.adPlaceholderList,
                  'banner--native-context_widget_relaunch_list',
                )}
                isLazy={false}
                preloadHeight={60}
                onClick={reachGoalAdClickCallback}
              />
            </li>
          )}
        </FeedWidgetLayoutRedesign>
      </div>
    );
  },
  (prev, next) =>
    prev.alias === next.alias &&
    _isequal(prev.clusters, next.clusters) &&
    prev.fetchData === next.fetchData,
);
