import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { CardWithRcm } from 'common/components/CardWithRcm';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { selectCurrentServerTime } from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType, defaultCallback } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

import { useSecondLevelWidgets } from '../../hooks';

import s from './styles.module.css';

type InjectorType = (index: number, puids: PuidsType) => React.ReactNode;

type SecondLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
  bottomAdName: Banners;
  level: number;
  floorIndex: number;
  indexOffset?: number;
  injectElements?: InjectorType;
};

/**
 * Компонент для отрисовки новостей начиная со второго экрана для вертикали Новости.
 * @param props.clusterIds - список идентификаторов кластеров;
 * @param props.puids - объект настроек для рекламы;
 * @param props.bottomAdName - название баннера в конце блока;
 * @param props.level - этаж для передачи в ЯМ;
 * @param props.floorIndex - индекс итерируемого этажа
 * @param props.indexOffset - офсет индексов для коректного подсчета в топ100;
 * @param props.injectElements - функция для внедрения компонентов внутрь списка новостей;
 */
export const SecondLevel = memo(function SecondLevel({
  clusterIds,
  puids,
  bottomAdName,
  level,
  floorIndex,
  indexOffset = 0,
  injectElements = defaultCallback as InjectorType,
}: SecondLevelPropsType) {
  const currentTime = useAppSelector(selectCurrentServerTime);

  const widgets = useSecondLevelWidgets(clusterIds[0], floorIndex);

  return (
    <ReachGoalLevelWrapper level={level} styles={s.root}>
      <div className={s.content}>
        <div className={s.newsBlock}>
          {clusterIds.map((clusterId, index) => {
            const element = injectElements(index, puids);
            const card = (
              <CardWithRcm
                key={clusterId}
                currentTime={currentTime}
                type="base-desktop"
                clusterId={clusterId}
                index={index + indexOffset}
                isLazy
              />
            );

            if (element) {
              return [card, element];
            }

            return card;
          })}
        </div>
      </div>
      <div className={s.widgets}>{widgets}</div>
      <div className={s.sideAd}>
        <Ad
          puids={puids}
          name={Banners['240x400']}
          withoutMarginBottom
          isLazy={false}
          isSticky
        />
      </div>
      <div className={s.bottomAd}>
        <Ad name={bottomAdName} puids={puids} withoutMarginBottom />
      </div>
    </ReachGoalLevelWrapper>
  );
});
