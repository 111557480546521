/**
 * Получение индекса кластера со смещением для топ100 списочных страниц
 * @param clusters – массив кластеров всех уровней
 * @param startIndex – индекс от которого считаем
 */
export const getGlobalIndex = (
  clusters: CardData['id'][][],
  startIndex: number,
) =>
  clusters
    .slice(0, startIndex)
    .reduce((acc, clusterArr) => clusterArr.length + acc, 0);
