import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { selectPageName } from 'common/redux/appController/selectors';
import { selectIsMobile } from 'common/redux/runtime/selectors';
import { cropImage, makeOrientationCrop, CROP_TYPES } from 'common/utils/crop';
import { s3Image, s3Resize, s3Format } from 'common/utils/s3';
import { APP_TYPE } from 'config/constants/devices';
import { AUTO } from 'config/constants/s3ImageTypes';
import { generateCrop } from 'utils/generateSrcsets';

import { IMAGES_SIZES_DIR, PAGE_IMAGE_TYPE, IMAGE_TYPES } from './config';

type PreloadMainImagePropsType = {
  image: CardData['image'];
};

type AppVersionType = keyof (typeof IMAGES_SIZES_DIR)[IMAGE_TYPES];

/**
 * Добавляет тег предзагрузки главной картинки
 * @param image - данные о картинке
 */
export const PreloadMainImage = memo(({ image }: PreloadMainImagePropsType) => {
  const isMobile = useSelector(selectIsMobile);
  const pageName = useSelector(selectPageName);

  if (!image) return null;

  const { url: imageUrl, s3 } = image;

  const appVersion = isMobile ? APP_TYPE.mobile : APP_TYPE.desktop;

  const pageImageType = PAGE_IMAGE_TYPE[pageName] || IMAGE_TYPES.cluster;

  const preloadImagesSizes =
    IMAGES_SIZES_DIR[pageImageType]?.[appVersion as AppVersionType] || [];

  const preloadImagesUrls = preloadImagesSizes.map(
    ({ width, height }: { width: number; height: number }) => {
      const s3Url = s3Image(imageUrl || '', [
        s3Resize({ number: 1, width, height }),
        s3Format(2, AUTO),
      ]);

      const cropValue = makeOrientationCrop({
        width,
        height,
        image,
        verticalType: CROP_TYPES.cTypeO,
        horizontalType: CROP_TYPES.cType,
      });

      if (!cropValue) return '';

      return s3 ? s3Url : cropImage(imageUrl, generateCrop(cropValue));
    },
  );

  return (
    <Helmet>
      {preloadImagesUrls.map((url: string) => (
        <link key={url} rel="preload" href={url} as="image" />
      ))}
    </Helmet>
  );
});
