import { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectWidgetsTopMainClusterIDs } from 'common/redux/commonData/widgets/topMainWidget/selectors';
import { selectWidgetsTopProjectClusterIDs } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { fetchFullRecsData } from 'common/redux/pages/topic/asyncs';
import { selectTopicPageClusterIds } from 'common/redux/pages/topic/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { useFetchRecsData } from 'desktop/hooks/useFetchRecsData';

/**
 * Компонент с логикой подрузки рекомендов для страницы топиков.
 */
export const TopicRecommends = memo(() => {
  const projectId = useSelector(selectProjectId);
  const topicClustersIds = useSelector(selectTopicPageClusterIds, shallowEqual);
  // Рекомендательные кластера из колонки
  const topProjectWidgetsClusterIds = useSelector(
    selectWidgetsTopProjectClusterIDs(projectId),
    shallowEqual,
  );
  const topMainWidgetsClustersIds = useSelector(
    selectWidgetsTopMainClusterIDs,
    shallowEqual,
  );
  const forceRedesign = useSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  /**
   * на странице редизайна нет виджета топ новостей поэтому topMainWidgetsClustersIds всегда пуст
   * и от этого checkForFetch всегда возвращает false внутри useFetchRecsData (клластера не подгружаются)
   */
  const excludedClustersArray = useMemo(
    () =>
      forceRedesign
        ? [topicClustersIds, topProjectWidgetsClusterIds]
        : [
            topicClustersIds,
            topProjectWidgetsClusterIds,
            topMainWidgetsClustersIds,
          ],
    [
      forceRedesign,
      topicClustersIds,
      topProjectWidgetsClusterIds,
      topMainWidgetsClustersIds,
    ],
  );

  useFetchRecsData({
    fetchRecsData: fetchFullRecsData,
    excludedClustersArray,
    rcmBlockType: RCM_BLOCK_TYPE.topicDesktop,
  });

  return null;
});
