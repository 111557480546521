import React from 'react';

import { ProjectNewsWidgetRedesign } from '../TopicNewsWidgetRedesign/components/ProjectNewsWidget';
import { TopicNewsWidgetRedesign } from '../TopicNewsWidgetRedesign/components/TopicNewsWidget';

import { PROJECT_IDS_BY_PROJECT } from './constants';

type GetWidgetsPropsType = {
  projectId: ProjectType['id'];
  projectAlias: ProjectType['alias'];
  clusterId: ClusterData['id'];
  topicAlias: TopicType['alias'];
  floorIndex: number;
};

/**
 * Хук, который отдает необходимые виджеты на втором уровне
 * @param projectId - id вертикали
 * @param projectAlias - alias вертикали
 * @param clusterId - id кластера
 * @param topicAlias - alias топика
 * @param floorIndex - индекс итерируемого этажа
 */
export const useSecondLevelWidgetOneColumn = ({
  projectId,
  topicAlias,
  floorIndex,
}: GetWidgetsPropsType) => {
  /**
   * Отключено в NEWS-12018
   * if (floorIndex === 0) {
   * return WIDGETS_BY_PROJECT_CONFIG[projectId].slice(1);
   * }
   */

  /*
   * Отключено в NEWS-12018
   * if (GREEN_PROJECT_ALIASES.includes(projectAlias)) {
   * return (
   *  <RecommendationWidget
   *    rcmKey={RCM_BLOCK_TYPE.topNewsWidgetDesktop}
   *    clusterId={clusterId}
   *  />
   * );
   * }
   */

  if (
    PROJECT_IDS_BY_PROJECT[projectId] &&
    floorIndex >= PROJECT_IDS_BY_PROJECT[projectId].length
  ) {
    return null;
  }

  /**
   * ВЕРНУТЬ floorIndex + 2 после раскомментирования блоков
   *  выше
   * Установлен в рамках задачи NEWS-12018
   */

  if (
    PROJECT_IDS_BY_PROJECT[projectId] &&
    floorIndex < PROJECT_IDS_BY_PROJECT[projectId].length
  ) {
    return (
      <ProjectNewsWidgetRedesign
        projectId={PROJECT_IDS_BY_PROJECT[projectId][floorIndex]}
        newsCount={7}
        level={floorIndex + 1}
      />
    );
  }

  return (
    <TopicNewsWidgetRedesign
      topicAlias={topicAlias}
      newsCount={7}
      level={floorIndex + 1}
    />
  );
};
