import cn from 'classnames';
import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { Card } from 'common/components/Card';
import { ReachGoalFirstCardWrapper } from 'common/components/metrics/ReachGoalFirstCardWrapper';
import { ReachGoalLevelWrapper } from 'common/components/metrics/ReachGoalLevelWrapper';
import { SberWidgetWrapper } from 'common/components/SberWidgetWrapper';
import {
  selectCurrentServerTime,
  selectIsRussia,
  selectProjectId,
  selectPromoWidgetEnabled,
  selectPromoWidgetPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/constants/banner/contants';
import { PuidsType } from 'config/constants/common';
import { ProjectNewsWidgetRedesign } from 'desktop/components/TopicNewsWidgetRedesign/components/ProjectNewsWidget';
import { TopicNewsWidgetRedesign } from 'desktop/components/TopicNewsWidgetRedesign/components/TopicNewsWidget';
import { useTopicAliasesForSideWidgets } from 'desktop/hooks/useTopicAliasesForSideWidgets';
import { useAppSelector } from 'store/hooks';

import {
  PROJECT_IDS_BY_PROJECT,
  WIDGETS_BY_PROJECT_CONFIG,
} from '../../constants';

import s from './styles.module.css';

type FirstLevelPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
};

/**
 * Компонент первого этажа новостной вертикали.
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - файл с настройками рекламы;.
 */
export const FirstLevel = memo(function FirstLevel({
  clusterIds,
  puids,
}: FirstLevelPropsType) {
  const projectId = useAppSelector(selectProjectId);
  // const projectAlias = useAppSelector(selectProjectAlias);
  const isRussiaRegion = useAppSelector(selectIsRussia);
  const isPromoWidgetEnabled = useAppSelector(selectPromoWidgetEnabled);
  const isPromoWidgetPlaceholderEnabled = useAppSelector(
    selectPromoWidgetPlaceholderEnabled,
  );
  const serverTime = useAppSelector(selectCurrentServerTime);

  const getTopicAliasForSideWidget = useTopicAliasesForSideWidgets();

  return (
    <div className={s.root}>
      <div className={s.main}>
        <ReachGoalLevelWrapper level={1} styles={s.news}>
          {clusterIds.map((clusterId, index) => {
            if (index === 0) {
              return (
                <ReachGoalFirstCardWrapper key={clusterId}>
                  <Card
                    key={clusterId}
                    currentTime={serverTime}
                    type="top-first-desktop"
                    clusterId={clusterId}
                    isAlternativeVersion
                    index={index + 1}
                  />
                </ReachGoalFirstCardWrapper>
              );
            }

            return (
              <Card
                key={clusterId}
                currentTime={serverTime}
                type="base-desktop"
                clusterId={clusterId}
                index={index + 1}
              />
            );
          })}
        </ReachGoalLevelWrapper>
      </div>
      <div className={s.sideWidgets}>
        {!!WIDGETS_BY_PROJECT_CONFIG[projectId] && (
          <div className={s.widget}>
            {WIDGETS_BY_PROJECT_CONFIG[projectId][0]}
          </div>
        )}
        {/*
         * <RecommendationWidget
         * rcmKey={RCM_BLOCK_TYPE.topNewsWidgetDesktop}
         * clusterId={clusterIds[0]}
         * isFirstRcmWidget
         * />
         */}
        {PROJECT_IDS_BY_PROJECT[projectId] ? (
          <ProjectNewsWidgetRedesign
            projectId={PROJECT_IDS_BY_PROJECT[projectId][0]}
            newsCount={7}
            level={1}
            withAd
            puids={puids}
          />
        ) : (
          <TopicNewsWidgetRedesign
            topicAlias={getTopicAliasForSideWidget(0)}
            newsCount={7}
            level={1}
            withAd
            puids={puids}
          />
        )}
      </div>
      <div className={s.sideAd}>
        <div className={s.sticky}>
          {isRussiaRegion && isPromoWidgetEnabled && (
            <Ad
              name={Banners.PromoWidget}
              puids={puids}
              isLazy={false}
              // Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился
              className={cn(
                s.promo,
                isPromoWidgetPlaceholderEnabled && s.visible,
              )}
              preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
              disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
            />
          )}
          <SberWidgetWrapper />
          <Ad
            puids={puids}
            name={Banners['240x400']}
            withoutMarginBottom
            isLazy={false}
          />
          {!!WIDGETS_BY_PROJECT_CONFIG[projectId] && (
            <div className={s.widget}>
              {WIDGETS_BY_PROJECT_CONFIG[projectId][0]}
            </div>
          )}
          <Ad
            name={Banners.Context}
            puids={puids}
            className={cn(
              s.adPlaceholder,
              'banner--native-context_widget_relaunch',
            )}
            loadedClassName={s.adPlaceholderLoaded}
            isLazy={false}
            preloadHeight={100}
          />
        </div>
      </div>
    </div>
  );
});
