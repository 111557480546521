import { selectCardById } from '../../entries/selectors';
import { selectWidgets } from '../selectors';

export const selectWidgetsVideo = (state: IAppState) =>
  selectWidgets(state).videoWidget;

export const selectWidgetsVideoClusterID = (state: IAppState) =>
  selectWidgetsVideo(state).clusterId;

export const selectWidgetsVideoCluster =
  (clusterId: CardData['id'] | null) => (state: IAppState) =>
    selectCardById(clusterId)(state) || null;

export const selectWidgetsVideoClusterInCardData = (state: IAppState) =>
  selectWidgetsVideoCluster(selectWidgetsVideoClusterID(state))(state);

export const selectWidgetsVideoClusterWithOptionalClusterId = (
  clusterId?: CardData['id'] | null,
) =>
  clusterId
    ? selectWidgetsVideoCluster(clusterId)
    : selectWidgetsVideoClusterInCardData;

export const selectWidgetsVideoLoadingState = (state: IAppState) =>
  selectWidgetsVideo(state).fetching;

export const selectWidgetsVideoError = (state: IAppState) =>
  selectWidgetsVideo(state).error;
