type ItemQueueType = {
  clusterId: string;
  callback: () => Promise<any>;
};

/**
 * Логика последовательной загрузки данных для рекоммендуемого виджета
 */
const initFIFOQueueRcm = () => ({
  isProcessing: false,
  queue: [] as ItemQueueType[],
  sessionId: '',

  async enqueue(item: ItemQueueType) {
    if (__SERVER__) {
      return;
    }

    this.queue.push(item);

    await this.next();
  },

  async next() {
    if (this.isProcessing || this.queue.length === 0) {
      return;
    }

    this.isProcessing = true;

    const item = this.queue.shift();

    if (!item) {
      this.isProcessing = false;

      return;
    }

    try {
      const { payload } = await item.callback();

      if (!this.sessionId) {
        this.sessionId = payload.sessionId;
      }

      this.isProcessing = false;

      await this.next();
    } catch (error) {
      this.isProcessing = false;

      await this.next();

      console.error(error);
    }
  },

  getQueue() {
    return this.queue;
  },

  getSessionId() {
    return this.sessionId;
  },
});

const FIFOQueueRcm = initFIFOQueueRcm();

export { FIFOQueueRcm };
