import { useMemo } from 'react';

import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { WINDOW_WIDTH_COMPACT } from 'config/constants/css';

/**
 * Мапка, определяющая количество кластеров в каждом этаже.
 * from - с какого элемента вырезать кластера из переданного массива. Обязателен
 * to - до какого элемента вырезать кластера. Не обязателен
 * repeatEvery - паттерн нарезки кластеров начиная с from и до самого конца кластера. Не обязателен
 *
 * Обязательно должен быть либо параметр to, либо repeatEvery.
 */
const NEWS_LEVELS_MAP = [
  { from: 0, to: 10 },
  { from: 10, to: 16 },
  { from: 16, to: 22 },
  { from: 22, to: 28 },
  { from: 28, repeatEvery: 8 },
];

const NEWS_LEVELS_MAP_COMPACT = [
  { from: 0, to: 16 },
  { from: 16, to: 22 },
  { from: 22, to: 28 },
  { from: 28, to: 34 },
  { from: 34, repeatEvery: 8 },
];

const isNumber = (num: unknown): num is number =>
  typeof num === 'number' && !Number.isNaN(Number(num));

type SliceClustersType = {
  clusterIds: CardData['id'][];
  isCompact: boolean;
};

/**
 * Функция нарезки массива кластеров для этажей.
 * @param clusterIds - массив с id кластеров;
 * @param isCompact - флаг компактной ширины экрана.
 */
export const sliceClusters = ({ clusterIds, isCompact }: SliceClustersType) => {
  const levelsMaps = isCompact ? NEWS_LEVELS_MAP_COMPACT : NEWS_LEVELS_MAP;

  return levelsMaps
    .reduce(
      (acc, levelInfo, levelIndex) => {
        if (isNumber(levelInfo?.from) && isNumber(levelInfo?.to)) {
          acc[levelIndex] = clusterIds.slice(levelInfo.from, levelInfo.to);

          return acc;
        }

        if (isNumber(levelInfo?.repeatEvery) && isNumber(levelInfo?.from)) {
          return [
            ...acc,
            ...clusterIds.slice(levelInfo?.from).reduce(
              (repeated, id, index) => {
                // Округляем этаж данной новости
                const currentLevelNumber = Math.floor(
                  index / levelInfo.repeatEvery!,
                );

                // Если подмассива ещё не существует - создаем его
                if (!repeated[currentLevelNumber]) {
                  repeated[currentLevelNumber] = [];
                }

                /**
                 * Массив разбивается на подмасисвы с шестью элементами в каждом.
                 *  [1, 2, 3, 4, 5, 6, 7, 8, 9] => [[1, 2, 3, 4, 5, 6], [7, 8, 9]]
                 */
                repeated[currentLevelNumber].push(id);

                return repeated;
              },
              [] as CardData['id'][][],
            ),
          ];
        }

        return acc;
      },
      [] as CardData['id'][][],
    )
    .filter((arr) => arr.length > 0);
};

/**
 * Хук для получения массива кластеров для этажей.
 * @param clusterIds - массив айдишников для нарезки;
 */
export const useSliceClusters = (clusterIds: CardData['id'][]) => {
  const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });

  const slicedClusters: CardData['id'][][] = useMemo(
    () =>
      sliceClusters({
        clusterIds,
        isCompact,
      }),
    [clusterIds, isCompact],
  );

  return { slicedClusters };
};
