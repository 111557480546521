import React, { memo, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { CardContextWrapper } from 'common/components/CardContextWrapper';
import { useBlockInitAtd, usePageViewAtd } from 'common/hooks/useRcm';
import { RegionHeader } from 'common/pages/headers/RegionHeader';
import { selectRegionClustersIds } from 'common/redux/commonData/regions/selectors';
import { fetchFullRecsData } from 'common/redux/pages/region/asyncs';
import {
  selectRegionExcludedClustersIds,
  selectRegionPageHasNextPage,
  selectRegionPageItem,
  selectRegionPagePuids,
  selectRegionPageRecommendClusterIds,
} from 'common/redux/pages/region/selectors';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { Base } from 'desktop/components/Base';
import { Trigger } from 'desktop/components/Trigger';
import { useSliceClusters } from 'desktop/hooks/useSliceClusters';

import s from '../../css/page.css';

import { RegionRecommends } from './RegionRecommends';

/** Компонент страницы региона */
const Region = memo(() => {
  const region = useSelector(selectRegionPageItem, shallowEqual);
  const regionClustersIds = useSelector(
    selectRegionClustersIds(region?.id),
    shallowEqual,
  );
  const regionRecommendClustersIds = useSelector(
    selectRegionPageRecommendClusterIds,
    shallowEqual,
  );
  const regionExcludedClustersIds = useSelector(
    selectRegionExcludedClustersIds,
    shallowEqual,
  );
  const regionPuids = useSelector(selectRegionPagePuids, shallowEqual);
  const regionHasNextPage = useSelector(selectRegionPageHasNextPage);

  const dataTitle = useMemo(
    () => ({
      name: region?.name,
      title: region?.longTitle || `Последние новости ${region?.namer ?? ''}`,
    }),
    [region],
  );

  const { slicedClusters } = useSliceClusters([
    ...regionClustersIds,
    ...regionRecommendClustersIds,
  ]);

  usePageViewAtd();
  useBlockInitAtd(RCM_BLOCK_TYPE.regionDesktop);

  return (
    <CardContextWrapper
      selectClustersIds={selectRegionClustersIds(region?.id)}
      rcmBlockType={RCM_BLOCK_TYPE.regionDesktop}
    >
      <div className={s.root}>
        <RegionHeader />
        <Base
          clusters={slicedClusters}
          puids={regionPuids}
          excludedIds={regionClustersIds}
          dataTitle={dataTitle}
        />
        <Trigger
          ComponentRecommends={RegionRecommends}
          fetchRecsData={fetchFullRecsData}
          excludedRecsIds={regionExcludedClustersIds}
          rcmBlockType={RCM_BLOCK_TYPE.regionDesktop}
          hasNextPage={regionHasNextPage}
        />
      </div>
    </CardContextWrapper>
  );
});

export default Region;
