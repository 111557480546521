import React, { forwardRef } from 'react';

import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';
import { WIDGET_TYPE } from 'desktop/components/FeedWidgetLayout/constants';

import { Item } from '../Item';
import { RecItem } from '../RecItem';

import s from './styles.module.css';

type TopNewsWidgetPropsType = {
  url?: string;
  clusters: CardData[];
  title: string;
  isError: boolean;
  onRefresh: () => void;
  top100Value: string;
  rcmKey?: RCM_BLOCK_TYPE;
  contextItemId?: CardData['id'];
  withCaptionTitle?: boolean;
  initialHeight?: boolean;
  isVisibleWithoutJS?: boolean;
  onClick?: () => void;
};

/**
 * Виджет топа новостей.
 * @param props.url - ссылка на страницу, на которую ведет виджет;
 * @param props.clusters - список кластеров;
 * @param props.title - заголовок блока;
 * @param props.isError - флаг ошибки загрузки новостей;
 * @param props.onRefresh - функция для перезагрузки виджета;
 * @param props.top100Value - значение топ100;
 * @param props.rcmKey - тип блока если новости от рекомендов;
 * @param props.contextItemId - id кластера для которого получены clusters, если новости от рекомендов;
 * @param props.withCaptionTitle - флаг, что тайтл виджета будет иметь тег h2;
 * @param props.initialHeight - флаг, что будет сброшена высота виджета;
 * @param props.onClick - функция клика по новости в виджете;
 * @param ref – реф для контейнера виджета, чтобы получать высоту выше;
 * @param props.isVisibleWithoutJS - флаг, что виджет виден без js.
 */
export const TopNewsWidget = forwardRef<HTMLDivElement, TopNewsWidgetPropsType>(
  (
    {
      url = '',
      clusters,
      title,
      isError,
      onRefresh,
      top100Value,
      rcmKey,
      contextItemId,
      withCaptionTitle,
      initialHeight,
      isVisibleWithoutJS,
      onClick,
    },
    ref,
  ) => (
    <ErrorBoundary componentName="FeedWidgetLayout">
      <FeedWidgetLayout
        styles={s}
        title={title}
        url={url}
        isError={isError}
        onRefresh={onRefresh}
        top100Value={top100Value}
        ref={ref}
        widgetType={WIDGET_TYPE.top}
        withCaptionTitle={withCaptionTitle}
        initialHeight={initialHeight}
        isVisibleWithoutJS={isVisibleWithoutJS}
      >
        {clusters.map(
          (
            {
              id,
              title: clusterTitle,
              publicationTime,
              url: clusterUrl,
              commentsCount,
            },
            index,
          ) =>
            rcmKey && contextItemId ? (
              <RecItem
                clusterId={id}
                rcmKey={rcmKey}
                contextItemId={contextItemId}
                key={id}
                title={clusterTitle}
                publicationTime={publicationTime}
                count={commentsCount}
                url={clusterUrl}
                index={index}
                onClick={onClick}
              />
            ) : (
              <Item
                key={id}
                title={clusterTitle}
                publicationTime={publicationTime}
                count={commentsCount}
                url={clusterUrl}
                index={index}
                onClick={onClick}
              />
            ),
        )}
      </FeedWidgetLayout>
    </ErrorBoundary>
  ),
);
