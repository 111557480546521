import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { DataTitleType } from 'common/pages/titles/CommonTitle';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { WINDOW_WIDTH_COMPACT } from 'config/constants/css';
import { FirstPageWithWidgetsByTop } from 'desktop/components/FirstPageWithWidgetsByTop';
import { LevelLayout } from 'desktop/components/LevelLayout';
import { WIDGETS_VALUES } from 'desktop/components/LevelLayout/constants';
import { useTopicAliasesForSideWidgets } from 'desktop/hooks/useTopicAliasesForSideWidgets';

const getGlobalIndex = (clusters: CardData['id'][][], startIndex: number) =>
  clusters
    .slice(0, startIndex)
    .reduce((acc, clusterArr) => clusterArr.length + acc, 0);

type BasePropsType = {
  clusters: string[][];
  puids: PuidsType;
  excludedIds: string[];
  dataTitle?: DataTitleType;
};

/**
 * Основа для страницы.
 * @param clusters - отображаемые кластера;
 * @param puids - пуиды страницы;
 * @param excludedIds - айдишники исключаемых кластеров;
 * @param dataTitle - данные для заголовка.
 */
export const Base = memo(
  ({ clusters, puids, excludedIds, dataTitle }: BasePropsType) => {
    const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });

    const getTopicAliasForSideWidget = useTopicAliasesForSideWidgets();

    return (
      <>
        {clusters[0] && (
          <FirstPageWithWidgetsByTop
            clusterIds={clusters[0]}
            puids={puids}
            isCompact={isCompact}
            excludedIds={excludedIds}
            dataTitle={dataTitle}
          />
        )}
        {clusters[1] && (
          <>
            <Ad name={Banners.Superfooter} puids={puids} />
            <LevelLayout
              clusterIds={clusters[1]}
              puids={puids}
              bannerName={Banners.Native}
              widgetName={
                isCompact ? WIDGETS_VALUES.VideoList : WIDGETS_VALUES.VideoCard
              }
              topic={getTopicAliasForSideWidget(0)}
              isCompact={isCompact}
              level={1}
              excludedIds={excludedIds}
              indexOffset={getGlobalIndex(clusters, 1) + 1}
            />
          </>
        )}
        {clusters[2] && (
          <>
            <Ad name={Banners.Parallax} puids={puids} />
            <LevelLayout
              clusterIds={clusters[2]}
              puids={puids}
              bannerName={Banners.Native}
              topic={getTopicAliasForSideWidget(1)}
              isCompact={isCompact}
              level={2}
              excludedIds={excludedIds}
              indexOffset={getGlobalIndex(clusters, 2) + 1}
            />
          </>
        )}
        {clusters[3] && (
          <>
            <Ad name={Banners['100x70']} puids={puids} />
            <LevelLayout
              clusterIds={clusters[3]}
              puids={puids}
              bannerName={Banners.Native}
              widgetName={WIDGETS_VALUES.SubscribeWidget}
              topic={getTopicAliasForSideWidget(2)}
              isCompact={isCompact}
              level={3}
              excludedIds={excludedIds}
              indexOffset={getGlobalIndex(clusters, 3) + 1}
            />
          </>
        )}
        {clusters[4] && (
          <>
            <Ad name={Banners['100x70']} puids={puids} />
            <LevelLayout
              clusterIds={clusters[4]}
              puids={puids}
              bannerName={Banners.Native}
              topic={getTopicAliasForSideWidget(3)}
              isCompact={isCompact}
              level={4}
              excludedIds={excludedIds}
              indexOffset={getGlobalIndex(clusters, 4) + 1}
            />
          </>
        )}
        {clusters[5] && (
          <>
            <Ad name={Banners['100x70']} puids={puids} />
            <LevelLayout
              clusterIds={clusters[5]}
              puids={puids}
              bannerName={Banners.Native}
              topic={getTopicAliasForSideWidget(4)}
              isCompact={isCompact}
              level={5}
              excludedIds={excludedIds}
              indexOffset={getGlobalIndex(clusters, 5) + 1}
            />
          </>
        )}
        {clusters?.slice(6).map((slicedPart, index) => {
          const offset = getGlobalIndex(clusters, index + 5) + 1;

          return (
            <React.Fragment key={slicedPart[0]}>
              <Ad name={Banners['100x70']} puids={puids} />
              <LevelLayout
                clusterIds={slicedPart}
                puids={puids}
                bannerName={Banners.Native}
                topic={getTopicAliasForSideWidget(index + 5)}
                isCompact={isCompact}
                level={index + 6}
                excludedIds={excludedIds}
                indexOffset={offset}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  },
);
