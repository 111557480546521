import _chunk from 'lodash.chunk';
import React, { useMemo } from 'react';

import { Ad } from 'common/components/Ad';
import { RamblerCalendar } from 'common/components/RamblerEmbeds/RamblerCalendar';
import { withErrorBoundary } from 'common/hoc/withErrorBoundary';
import { selectProjectId } from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';
import { getGlobalIndex } from 'utils/getGlobalIndex';

import { FirstLevel } from './components/FirstLevel';
import { SecondLevel } from './components/SecondLevel';
import {
  INJECTORS,
  LAST_CHUNK_SIZE,
  SLICE_CONFIG,
  WIDGETS_BY_PROJECT_CONFIG,
} from './constants';

import s from './styles.module.css';

import 'desktop/css/banner--native-context_list_relaunch.css';
import 'desktop/css/banner--native-context_widget_relaunch.css';
import 'desktop/css/banner--native-context_widget_relaunch_list.css';

type BaseRedesignOneColumnPropsType = {
  clusterIds: CardData['id'][];
  puids: PuidsType;
};

/**
 * Базовый костяк для всей домашней страницы вертикалей всех кроме news а также рубрик.
 * @param props.clusterIds - массив id кластеров;
 * @param props.puids - объект с настройками для рекламы.
 */
export const BaseRedesignOneColumn = withErrorBoundary(
  function BaseRedesignOneColumn({
    puids,
    clusterIds,
  }: BaseRedesignOneColumnPropsType) {
    const projectId = useAppSelector(selectProjectId);

    const slicedClusters = useMemo(() => {
      const copy = [...clusterIds];

      const sliced = SLICE_CONFIG.map((value) =>
        copy.length > 0 ? copy.splice(0, value) : undefined,
      ).filter((val) => !!val) as string[][];

      if (copy.length <= 0) {
        return sliced;
      }

      return [...sliced, ..._chunk(copy, LAST_CHUNK_SIZE)];
    }, [clusterIds]);

    return (
      <div className={s.root}>
        <FirstLevel clusterIds={slicedClusters[0]} puids={puids} />
        <div className={s.bottomBlock}>
          <RamblerCalendar />
          <Ad name={Banners['100x70']} puids={puids} withoutMarginBottom />
        </div>
        {slicedClusters.slice(1).map((clusterIds, index) => (
          <SecondLevel
            key={`level_${index}`}
            floorIndex={index}
            clusterIds={clusterIds}
            injectElements={
              index >= INJECTORS.length
                ? INJECTORS[INJECTORS.length - 1]
                : INJECTORS[index]
            }
            puids={puids}
            bottomAdName={Banners.Superfooter}
            widgetsCompact={
              WIDGETS_BY_PROJECT_CONFIG[projectId]?.[index + 1] || null
            }
            indexOffset={getGlobalIndex(slicedClusters, index + 1)}
            level={index + 2}
          />
        ))}
      </div>
    );
  },
);
