import React, { memo, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { CardContextWrapper } from 'common/components/CardContextWrapper';
import { useBlockInitAtd, usePageViewAtd } from 'common/hooks/useRcm';
import { TopicHeader } from 'common/pages/headers/TopicHeader';
import { fetchFullRecsData } from 'common/redux/pages/topic/asyncs';
import {
  selectTopicExcludedClustersIds,
  selectTopicPageClusterIds,
  selectTopicPageHasNextPage,
  selectTopicPageItem,
  selectTopicPagePuids,
  selectTopicPageRecommendClusterIds,
} from 'common/redux/pages/topic/selectors';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { GREEN_PROJECT_IDS } from 'common/routes/home/constants';
import { GREEN_TOPIC_ALIASES } from 'common/routes/topic/constants';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { Base } from 'desktop/components/Base';
import { BaseRedesignOneColumn } from 'desktop/components/BaseRedesignOneColumn';
import { Trigger } from 'desktop/components/Trigger';
import { useSliceClusters } from 'desktop/hooks/useSliceClusters';

import s from '../../css/page.css';

import { TopicRecommends } from './TopicRecommends';

/** Компонент страницы топика */
const Topic = memo(() => {
  const topic = useSelector(selectTopicPageItem, shallowEqual);
  const topicClustersIds = useSelector(selectTopicPageClusterIds, shallowEqual);
  const topicRecommendClustersIds = useSelector(
    selectTopicPageRecommendClusterIds,
    shallowEqual,
  );
  const topicExcludedClustersIds = useSelector(
    selectTopicExcludedClustersIds,
    shallowEqual,
  );
  const topicPuids = useSelector(selectTopicPagePuids, shallowEqual);
  const topicHasNextPage = useSelector(selectTopicPageHasNextPage);
  const forceRedesign = useSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const dataTitle = useMemo(
    () => ({
      name: topic?.name,
      title: topic?.title || `Последние новости ${topic?.name}`,
    }),
    [topic],
  );

  const clusterIds = useMemo(
    () => [...topicClustersIds, ...topicRecommendClustersIds],
    [topicClustersIds, topicRecommendClustersIds],
  );

  const { slicedClusters } = useSliceClusters([
    ...topicClustersIds,
    ...topicRecommendClustersIds,
  ]);

  usePageViewAtd();
  useBlockInitAtd(RCM_BLOCK_TYPE.topicDesktop);

  return (
    <CardContextWrapper
      selectClustersIds={selectTopicPageClusterIds}
      rcmBlockType={RCM_BLOCK_TYPE.topicDesktop}
    >
      <div className={s.root}>
        <TopicHeader />
        {forceRedesign ? (
          <BaseRedesignOneColumn puids={topicPuids} clusterIds={clusterIds} />
        ) : (
          <Base
            clusters={slicedClusters}
            puids={topicPuids}
            excludedIds={topicClustersIds}
            dataTitle={dataTitle}
          />
        )}
        {topic &&
          !(
            GREEN_TOPIC_ALIASES.includes(topic.alias) ||
            GREEN_PROJECT_IDS.includes(topic.project_id)
          ) && (
            <Trigger
              ComponentRecommends={TopicRecommends}
              fetchRecsData={fetchFullRecsData}
              excludedRecsIds={topicExcludedClustersIds}
              rcmBlockType={RCM_BLOCK_TYPE.topicDesktop}
              hasNextPage={topicHasNextPage}
            />
          )}
      </div>
    </CardContextWrapper>
  );
});

export default Topic;
