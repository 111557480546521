import cn from 'classnames';
import React, { memo } from 'react';

import s from './styles.module.css';

/**
 * Компонент скелетон для виджета топа
 */
export const TopWidgetSkeleton = memo(() => (
  <div className={s.textGroup}>
    <div className={s.text} />
    <div className={s.text} />
    <div className={cn(s.text, s.text3)} />
  </div>
));
