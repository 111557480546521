import React, { memo } from 'react';

import { VideoWidget } from 'desktop/components/VideoWidget/card';

import { ListCard } from './components/ListCard';
import { MainCard } from './components/MainCard';
import { SecondaryCard } from './components/SecondaryCard';
import { CARD_TYPES } from './constants';

export type CardPropsType = {
  clusterId: CardData['id'] | null;
  type: CARD_TYPES;
  index: number;
  isCompactCard?: boolean;
  onClick?: () => void;
};

/**
 * Компонент - switcher карточек, исходя из пришедшего в пропсы типа, выбирает какую карточку выбрать и отрисовать.
 * @param clusterId - id кластера для передачи в функциональный компонент карточки;
 * @param type - Тип карточки, используется для выбора компонента для отрисовки;
 * @param index - индекс карточки в общем топе;
 * @param isCompactCard - флаг что карточка должна иметь компактный вид (редизайн вертикали 2, максимальная ширина 430px);
 * @param onClick - функция клик по карточке.
 */
export const CardSwitcher = memo(
  ({ clusterId, type, index, isCompactCard, onClick }: CardPropsType) => {
    switch (type) {
      case CARD_TYPES.MAIN:
        return (
          <MainCard clusterId={clusterId} index={index} onClick={onClick} />
        );

      case CARD_TYPES.SECONDARY:
        return <SecondaryCard clusterId={clusterId} index={index} />;

      case CARD_TYPES.LIST:
        return (
          <ListCard
            clusterId={clusterId}
            index={index}
            isCompactCard={isCompactCard}
            onClick={onClick}
          />
        );

      case CARD_TYPES.VIDEO_CARD:
      case CARD_TYPES.VIDEO_LIST:
        return <VideoWidget clusterId={clusterId} type={type} index={index} />;

      default:
        return null;
    }
  },
);
