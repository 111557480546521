import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectWidgetsVideoClusterID } from 'common/redux/commonData/widgets/videoWidget/selectors';
import { CardSwitcher } from 'desktop/components/Card';

import { STYLES } from './card';

type VideoWidgetWrapperPropsType = {
  type: keyof typeof STYLES;
};

export const VideoWidgetWrapper = memo(
  ({ type }: VideoWidgetWrapperPropsType) => {
    const videoClusterId = useSelector(selectWidgetsVideoClusterID);

    // index в данном контексте не имеет значения, поэтому отправляем заглушку
    return <CardSwitcher clusterId={videoClusterId} type={type} index={0} />;
  },
);
