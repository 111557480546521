import React, { memo } from 'react';

import { SberWidgetIcon } from 'icons';

import { getSberLink } from '../../utils';

import s from './styles.module.css';

type SberWidgetPropsType = {
  erid: string;
};

/**
 * Рекламный виджет сбера.
 * @param props.erid - уникальный токен для ссылки СберВиджета.
 */
export const SberWidget = memo(({ erid }: SberWidgetPropsType) => (
  <div className={s.root}>
    <a
      href={getSberLink(erid)}
      className={s.container}
      target="_blank"
      rel="noreferrer"
    >
      <SberWidgetIcon className={s.icon} />
    </a>
    <div className={s.adMeta}>
      <span className={s.title}>Реклама</span>
      <span className={s.content}>Рекламодатель: ПАО Сбербанк</span>
    </div>
  </div>
));
